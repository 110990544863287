import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useCallback, useState } from 'react';
import { Chart } from 'chart.js';
import { generateID } from './utils/generate-id/generateID';
import { noop } from './utils/noop';
export const ReactChart = ({ id, data, options, type, plugins, updateMode, height, width, }) => {
    const chartInstance = useRef({
        update: noop,
        destroy: noop,
    });
    const [CHART_ID] = useState(id || generateID('Chart'));
    useEffect(() => {
        chartInstance.current.data = data;
        chartInstance.current.options = options;
        chartInstance.current.update(updateMode);
    }, [data, options]);
    const nodeRef = useCallback((node) => {
        chartInstance.current.destroy();
        if (node) {
            chartInstance.current = new Chart(node, {
                type,
                data,
                options,
                plugins,
            });
        }
    }, []);
    return (_jsx("canvas", { ref: nodeRef, height: height, width: width, id: CHART_ID, role: "chart" }));
};
ReactChart.register = Chart.register || noop;
